<template>
  <div>
    <!-- Modal  start-->
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      centered
      title="Modal title 2"
      size="lg"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">{{ titleModal }}</h6>
        <CButtonClose @click="CloseModal()" class="text-white" />
      </template>
      <div>
        <CRow>
          <CCol md="6">
            <CInput
              label="Source name"
              horizontal
              v-model="SourceData.SourceName"
            />
          </CCol>
          <CCol md="6">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-3">Status</label>
              <CSwitch
                style="padding-top: 5px"
                color="info"
                shape="pill"
                labelOn="on"
                labelOff="off"
                :checked.sync="SourceData.isActive"
              />
            </div>
            <!-- <div class="col-md-3">
                <label class="">Status</label>
              </div> -->
            <!-- <div class="col-md-9 pr-0">
                
              </div> -->
          </CCol>
        </CRow>
      </div>

      <template #footer>
        <CButton
          @click="SaveData('Save')"
          v-if="viewmode !== 'view'"
          color="primary"
        >
          Save
        </CButton>
        <CButton @click="CloseModal()" id="btnCancel" color="secondary">
          Cancel
        </CButton>
      </template>
    </CModal>
    <!-- Modal  end-->
  </div>
</template>
<style>
.c-switch-info .c-switch-input:checked + .c-switch-slider {
  background-color: #62e19c;
  border-color: #3cbd76;
}
.c-switch-info .c-switch-input:checked + .c-switch-slider::before {
  border-color: #3cbd76;
}
</style>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import store from "../../store";
import axios from "axios";

var URL_Insert = `${store.getters.URL}/api/MasterSource/insert`;
var URL_Update = `${store.getters.URL}/api/MasterSource/update`;
const titleAdd = "Add Source";
const titleEdit = "Edit Source";

export default {
  name: "AddOrUpdateBuModal",
  components: {
    vSelect,
  },
  data() {
    return {
      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      titleModal: titleAdd,
      SourceData: {
        SourceId: 0,
        SourceName: null,
        isActive: true,
      },
    };
  },
  props: {
    showModal: Boolean,
    item: Object,
    viewmode: {
      type: String,
      default: "view",
      required: false,
    },
  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("AddOrUpdateSourceModal-updated", v);
      },
    },
  },
  mounted() {},
  methods: {
    setDefault() {
      this.SourceData.SourceId = 0;
      this.SourceData.SourceName = null;
      this.SourceData.isActive = true;
    },
    CloseModal() {
      this.isshow = false;
      this.setDefault();
    },
    SaveData(action) {
      if (this.SourceData.SourceId > 0) {
        this.UpdateData();
      } else {
        this.AddData();
      }
    },
    AddData() {
      axios
        .post(URL_Insert, this.mapDTO(this.SourceData))
        .then((res) => {
          if (res.status === 200) {
            this.$_toast_Noti("success", "success", "");
            this.CloseModal();
            this.$emit("save-source-success");
          }
        })
        .catch((err) => {
          console.error("err insret bu", err);
        });
    },
    UpdateData() {
      axios
        .put(URL_Update, this.mapDTO(this.SourceData))
        .then((res) => {
          if (res.status === 200) {
            this.$_toast_Noti("success", "success", "");
            this.CloseModal();
            this.$emit("save-source-success");
          }
        })
        .catch((err) => {
          console.error("err update bu", err);
        });
    },
    mapDTO(item) {
      let param = {
        SourceID: 0,
        SourceDesc: null,
        IsActive: true,
      };

      param.SourceID = item.SourceId;
      param.SourceDesc = item.SourceName;
      param.IsActive = item.isActive;

      return param;
    },
  },
  watch: {
    showModal: function (newVal, oldVal) {
      // console.log("showModal", newVal);
      // console.log("item", this.item);
      if (newVal) {
        let temp = this.item;
        if (temp != null) {
          this.titleModal = titleEdit;
          this.SourceData.SourceId = temp.SourceID;
          this.SourceData.SourceName = temp.SourceDesc;
          this.SourceData.isActive = temp.IsActive;
        } else {
          this.titleModal = titleAdd;
          this.setDefault();
        }
      }
    },
  },
};
</script>
